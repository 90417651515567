import React, { lazy, Suspense, useContext } from "react";

import { Route, Routes } from "react-router-dom";
import { authContext } from "./MainComponent";

const ActivoFijoActivo = lazy(
  () => import("./Content/ActivoFijo/Activo/Activo")
);
const ActivoFijoCategoria = lazy(
  () => import("./Content/ActivoFijo/Categoria/Categoria")
);
const ActivoFijoDepartamento = lazy(
  () => import("./Content/ActivoFijo/Departamento/Departamento")
);
const ActivoFijoDepreciacion = lazy(
  () => import("./Content/ActivoFijo/Depreciacion/Depreciacion")
);
const ActivoFijoGrupo = lazy(() => import("./Content/ActivoFijo/Grupo/Grupo"));
const ActivoFijoResponsable = lazy(
  () => import("./Content/ActivoFijo/Responsable/Responsable")
);
const BancoCargo = lazy(() => import("./Content/Banco/Cargo/Cargo"));
const BancoCheque = lazy(() => import("./Content/Banco/Cheque/Cheque"));
const BancoChequeConfiguracionImpresion = lazy(
  () => import("./Content/Banco/ChequeConfiguracionImpresion/Configuracion")
);
const BancoChequeOtros = lazy(
  () => import("./Content/Banco/ChequeOtros/Cheque")
);
const BancoChequeAplicador = lazy(
  () => import("./Content/Banco/ChequeAplicador/Aplicador")
);
const BancoConciliacion = lazy(
  () => import("./Content/Banco/Conciliacion/Conciliacion")
);
const BancoCuenta = lazy(() => import("./Content/Banco/Cuenta/Cuenta"));
const BancoCredito = lazy(() => import("./Content/Banco/Credito/Credito"));
const BancoSolicitud = lazy(
  () => import("./Content/Banco/Solicitud/Solicitud")
);
const BancoSolicitudAutorizar = lazy(
  () => import("./Content/Banco/SolicitudAutorizar/SolicitudAutorizar")
);
const BancoSolicitudOtros = lazy(
  () => import("./Content/Banco/SolicitudOtros/Solicitud")
);
const BancoTransferencia = lazy(
  () => import("./Content/Banco/Transferencia/Transferencia")
);
const BancoTransferenciaCuenta = lazy(
  () => import("./Content/Banco/TransferenciaCuenta/TransferenciaCuenta")
);
const BancoTransferenciaAplicador = lazy(
  () => import("./Content/Banco/TransferenciaAplicador/Aplicador")
);
const BancoTransferenciaOtros = lazy(
  () => import("./Content/Banco/TransferenciaOtros/Transferencia")
);
const BancoDeposito = lazy(() => import("./Content/Banco/Deposito/Deposito"));
const BancoReporte = lazy(() => import("./Content/Banco/Reporte/Reporte"));
const Compania = lazy(
  () => import("./Content/Configuraciones/Compania/Compania")
);
const Companias = lazy(
  () => import("./Content/Configuraciones/Companias/Companias")
);
const Importaciones = lazy(
  () => import("./Content/Configuraciones/Importacion/PanelImportacion")
);
const ContabilidadCatalogo = lazy(
  () => import("./Content/Contabilidad/catalogo/catalogo")
);
const ContabilidadCentroCosto = lazy(
  () => import("./Content/Contabilidad/CentroCosto/CentroCosto")
);
const ContabilidadConfiguraciones = lazy(
  () => import("./Content/Contabilidad/Configuracion/Configuracion")
);
const ContabilidadEjercicioContable = lazy(
  () => import("./Content/Contabilidad/EjercicioContable/EjercicioContable")
);
const ContabilidadEntradaDiario = lazy(
  () => import("./Content/Contabilidad/EntradaDiario/EntradaDiario")
);
const ContabilidadPasarMovimientoCuenta = lazy(
  () =>
    import("./Content/Contabilidad/PasarMovimientoCuenta/PasarMovimientoCuenta")
);
const ContabilidadPeriodoContable = lazy(
  () => import("./Content/Contabilidad/PeriodoContable/PeriodoContable")
);
const ContabilidadPresupuesto = lazy(
  () => import("./Content/Contabilidad/Presupuesto/Presupuesto")
);
const ContabilidadReportes = lazy(
  () => import("./Content/Contabilidad/Reportes/Reporte")
);
const ContabilidadReportesDGII = lazy(
  () => import("./Content/Contabilidad/ReportesDGII/Reporte")
);
const CuentasCobrarClienteGrupo = lazy(
  () => import("./Content/CuentasCobrar/clienteGrupo/clienteGrupo")
);
const CuentasCobrarCliente = lazy(
  () => import("./Content/CuentasCobrar/cliente/cliente")
);
const CuentasCobrarClienteLocalidad = lazy(
  () => import("./Content/CuentasCobrar/ClienteLocalidad/Localidad")
);
const CuentasCobrarCotizacion = lazy(
  () => import("./Content/CuentasCobrar/Cotizacion/Cotizacion")
);
const CuentasCobrarCotizacionEstado = lazy(
  () => import("./Content/CuentasCobrar/CotizacionEstado/CotizacionEstado")
);
const CuentasCobrarCuadreCaja = lazy(
  () => import("./Content/CuentasCobrar/cuadreCaja/Consulta")
);
const CuentasCobrarDespacho = lazy(
  () => import("./Content/CuentasCobrar/Despacho/Despacho")
);
const CuentasCobrarConfiguracion = lazy(
  () => import("./Content/CuentasCobrar/Configuracion/Configuracion")
);
const CuentasCobrarFactura = lazy(
  () => import("./Content/CuentasCobrar/Factura/Factura")
);
const CuentasCobrarNotaCredito = lazy(
  () => import("./Content/CuentasCobrar/NotaCredito/NotaCredito")
);
const CuentasCobrarNotaCreditoAplicador = lazy(
  () => import("./Content/CuentasCobrar/NotaCreditoAplicador/Aplicador")
);
const CuentasCobrarNotaDebito = lazy(
  () => import("./Content/CuentasCobrar/NotaDebito/NotaDebito")
);
const CuentasCobrarPrefactura = lazy(
  () => import("./Content/CuentasCobrar/Prefactura/Prefactura")
);
const CuentasCobrarProforma = lazy(
  () => import("./Content/CuentasCobrar/Proforma/Proforma")
);
const CuentasCobrarRecibo = lazy(
  () => import("./Content/CuentasCobrar/Recibo/Recibo")
);
const CuentasCobrarReciboAplicador = lazy(
  () => import("./Content/CuentasCobrar/ReciboAplicador/Aplicador")
);
const CuentasCobrarReciboManual = lazy(
  () => import("./Content/CuentasCobrar/ReciboManual/ReciboManual")
);
const CuentasCobrarRestaurantOrdenMesa = lazy(
  () => import("./Content/CuentasCobrar/MesaOrden/MesaOrden")
);
const CuentasCobrarRestaurantMesa = lazy(
  () => import("./Content/CuentasCobrar/Mesa/Mesa")
);
const CuentasCobrarVendedor = lazy(
  () => import("./Content/CuentasCobrar/vendedor/vendedor")
);
const CuentasCobrarReportes = lazy(
  () => import("./Content/CuentasCobrar/Reportes/Reporte")
);
const CuentasCobrarResumenFacturaConsumo = lazy(
  () =>
    import(
      "./Content/CuentasCobrar/ResumenFacturaConsumo/ResumenFacturaConsumo"
    )
);
const CuentasCobrarSalidasCaja = lazy(
  () => import("./Content/CuentasCobrar/SalidasCaja/SalidasCaja")
);
const CuentasPagarBuscador = lazy(
  () => import("./Content/CuentasPagar/Buscador/Buscador")
);
const CuentasPagarConfiguracion = lazy(
  () => import("./Content/CuentasPagar/Configuracion/Configuracion")
);
const CuentasPagarFacturaCompra = lazy(
  () => import("./Content/CuentasPagar/FacturaCompra/FacturaCompra")
);
const CuentasPagarFacturaServicio = lazy(
  () => import("./Content/CuentasPagar/FacturaServicio/FacturaServicio")
);
const CuentasPagarNotaCredito = lazy(
  () => import("./Content/CuentasPagar/NotaCredito/NotaCredito")
);
const CuentasPagarNotaCreditoAplicador = lazy(
  () => import("./Content/CuentasPagar/NotaCreditoAplicador/Aplicador")
);
const CuentasPagarNotaDebito = lazy(
  () => import("./Content/CuentasPagar/NotaDebito/NotaDebito")
);
const CuentasPagarOrdenCompra = lazy(
  () => import("./Content/CuentasPagar/OrdenCompra/OrdenCompra")
);
const CuentasPagarPesada = lazy(
  () => import("./Content/CuentasPagar/Pesada/Pesada")
);
const CuentasPagarPesador = lazy(
  () => import("./Content/CuentasPagar/Pesador/Pesador")
);
const CuentasPagarReembolso = lazy(
  () => import("./Content/CuentasPagar/ReembolsoGasto/Reembolso")
);
const CuentasPagarComprobanteEspecial = lazy(
  () => import("./Content/CuentasPagar/ComprobanteEspecial/ComprobanteEspecial")
);
const CuentasPagarReportes = lazy(
  () => import("./Content/CuentasPagar/Reportes/Reporte")
);
const CuentasPagarSuplidor = lazy(
  () => import("./Content/CuentasPagar/Suplidor/Suplidor")
);
const CuentasPagarSuplidorGrupo = lazy(
  () => import("./Content/CuentasPagar/SuplidorGrupo/SuplidorGrupo")
);
const DashBoard = lazy(() => import("./Content/DashBoard/DashBoard"));
const Eventos = lazy(() => import("./Content/Configuraciones/Evento/Evento"));
const FinanciamientoPrestamoAutorizar = lazy(
  () => import("./Content/Financiamiento/PrestamoAutorizar/PrestamoAutorizar")
);
const FinanciamientoConfiguracion = lazy(
  () => import("./Content/Financiamiento/Configuracion/Configuracion")
);
const FinanciamientoChequePrestamo = lazy(
  () => import("./Content/Financiamiento/ChequePrestamo/ChequePrestamo")
);
const FinanciamientoPrestamo = lazy(
  () => import("./Content/Financiamiento/Prestamo/Prestamo")
);
const FinanciamientoRecibo = lazy(
  () => import("./Content/Financiamiento/Recibo/Recibo")
);
const FinanciamientoReporte = lazy(
  () => import("./Content/Financiamiento/Reportes/Reporte")
);
const FinanciamientoSolicitudPagoPrestamo = lazy(
  () =>
    import(
      "./Content/Financiamiento/SolicitudPagoPrestamo/SolicitudPagoPrestamo"
    )
);
const FinanciamientoSolicitudPagoPrestamoAutorizar = lazy(
  () => import("./Content/Banco/SolicitudAutorizar/SolicitudAutorizar")
);
const FinanciamientoTransferenciaPrestamo = lazy(
  () =>
    import(
      "./Content/Financiamiento/TransferenciaPrestamo/TransferenciaPrestamo"
    )
);
const InventarioAlmacen = lazy(
  () => import("./Content/Inventario/Almacen/Almacen")
);
const InventarioAlmacenGrupo = lazy(
  () => import("./Content/Inventario/AlmacenGrupo/AlmacenGrupo")
);
const InventarioConfiguracion = lazy(
  () => import("./Content/Inventario/Configuracion/Configuracion")
);
const InventarioConduceAlmacen = lazy(
  () => import("./Content/Inventario/ConduceAlmacen/ConduceAlmacen")
);
const InventarioCorteInventario = lazy(
  () => import("./Content/Inventario/CorteInventario/CorteInventario")
);
const InventarioCorteInventarioConteo = lazy(
  () =>
    import("./Content/Inventario/CorteInventarioConteo/CorteInventarioConteo")
);
const InventarioEntradaAlmacen = lazy(
  () => import("./Content/Inventario/EntradaAlmacen/EntradaAlmacen")
);
const InventarioLiquidacionAduanalTipoGasto = lazy(
  () => import("./Content/Inventario/LiquidacionAduanalTipoGasto/TipoGasto")
);
const InventarioLiquidacionAduanal = lazy(
  () => import("./Content/Inventario/LiquidacionAduanal/LiquidacionAduanal")
);
const InventarioProduccion = lazy(
  () => import("./Content/Inventario/Produccion/Produccion")
);
const InventarioProduccionEmpacador = lazy(
  () => import("./Content/Inventario/Empacador/Empacador")
);
const InventarioProduccionEmpaque = lazy(
  () => import("./Content/Inventario/ProduccionEmpaque/ProduccionEmpaque")
);
const InventarioProduccionEmpaqueConfirmar = lazy(
  () =>
    import(
      "./Content/Inventario/ProduccionEmpaqueConfirmar/ProduccionEmpaqueConfirmar"
    )
);
const InventarioProduccionFormula = lazy(
  () => import("./Content/Inventario/ProduccionFormula/ProduccionFormula")
);
const InventarioProducto = lazy(
  () => import("./Content/Inventario/producto/producto")
);
const InventarioProyecto = lazy(
  () => import("./Content/Inventario/Proyecto/Proyecto")
);
const InventarioTipoInventario = lazy(
  () => import("./Content/Inventario/TipoInventario/TipoInventario")
);
const InventarioGrupoProducto = lazy(
  () => import("./Content/Inventario/productoGrupo/productoGrupo")
);
const InventarioReportes = lazy(
  () => import("./Content/Inventario/Reportes/Reporte")
);
const InventarioSubGrupoProducto = lazy(
  () => import("./Content/Inventario/SubGrupo/SubGrupo")
);
const InventarioSalidaAlmacen = lazy(
  () => import("./Content/Inventario/SalidaAlmacen/SalidaAlmacen")
);
const InventarioRequisicion = lazy(
  () => import("./Content/Inventario/Requisicion/Requisicion")
);
const InventarioRequisicionAutorizar = lazy(
  () => import("./Content/Inventario/RequisicionAutorizar/RequisicionAutorizar")
);
const InventarioTransferenciaAlmacen = lazy(
  () => import("./Content/Inventario/TransferenciaAlmacen/TransferenciaAlmacen")
);
const Nomina = lazy(() => import("./Content/Nomina/Nomina/Nomina"));
const NominaConfiguracion = lazy(
  () => import("./Content/Nomina/Configuracion/Configuracion")
);
const NominaDepartamento = lazy(
  () => import("./Content/Nomina/Departamento/Departamento")
);
const NominaEmpleado = lazy(() => import("./Content/Nomina/Empleado/Empleado"));
const NominaPrestamo = lazy(() => import("./Content/Nomina/Prestamo/Prestamo"));
const NominaNovedad = lazy(() => import("./Content/Nomina/Novedad/Novedad"));
const NominaNovedadFijo = lazy(
  () => import("./Content/Nomina/NovedadFijo/NovedadFijo")
);
const NominaNovedadVariable = lazy(
  () => import("./Content/Nomina/NovedadVariable/NovedadVariable")
);

const OrdenServicioTecnico = lazy(
  () => import("./Content/OrdenServicio/Tecnico/Tecnico")
);

const OrdenServicioEstado = lazy(
  () => import("./Content/OrdenServicio/Estado/Estado")
);

const OrdenServicioTipo = lazy(
  () => import("./Content/OrdenServicio/Tipo/Tipo")
);

const OrdenServicioOrden = lazy(
  () => import("./Content/OrdenServicio/Orden/Orden")
);

const Perfil = lazy(() => import("./Content/Perfil/Perfil"));
const RRHHAccion = lazy(() => import("./Content/Nomina/Accion/Accion"));
const RRHHNominaRegalia = lazy(
  () => import("./Content/Nomina/NominaRegalia/Nomina")
);
const RRHHPosicion = lazy(() => import("./Content/Nomina/Posicion/Posicion"));
const RRHHReporte = lazy(() => import("./Content/Nomina/Reportes/Reporte"));
const RRHHSolicitudEmpleo = lazy(
  () => import("./Content/Nomina/SolicitudEmpleo/Solicitud")
);
const SueldoPorNomina = lazy(
  () => import("./Content/Nomina/SueldoPorNomina/SueldoPorNomina")
);
const TallerMecanicaArea = lazy(
  () => import("./Content/TalleMecanica/Area/Area")
);
const TallerMecanicaAreaEstadosServicio = lazy(
  () =>
    import("./Content/TalleMecanica/AreaEstadosServicios/AreaEstadosServicios")
);
const TallerMecanicaConfiguracion = lazy(
  () => import("./Content/TalleMecanica/Configuracion/Configuracion")
);
const TallerMecanicaMecanico = lazy(
  () => import("./Content/TalleMecanica/Mecanico/Mecanico")
);
const TallerMecanicaOrden = lazy(
  () => import("./Content/TalleMecanica/Orden/Orden")
);
const TallerMecanicaOrdenTipo = lazy(
  () => import("./Content/TalleMecanica/OrdenTipo/OrdenTipo")
);
const TallerMecanicaVehiculo = lazy(
  () => import("./Content/TalleMecanica/Vehiculo/Vehiculo")
);
const TallerMecanicaReporte = lazy(
  () => import("./Content/TalleMecanica/Reportes/Reporte")
);
const Usuarios = lazy(
  () => import("./Content/Configuraciones/Usuarios/Usuarios")
);
const VisitaConfiguracion = lazy(
  () => import("./Content/Visita/Configuracion/Configuracion")
);
const VisitaArea = lazy(() => import("./Content/Visita/Area/Area"));
const VisitaDestino = lazy(() => import("./Content/Visita/Destino/Destino"));
const VisitaVisita = lazy(() => import("./Content/Visita/Visita/Visita"));
const VisitaVisitante = lazy(
  () => import("./Content/Visita/Visitante/Visitante")
);
const VisitaVisitanteTipo = lazy(
  () => import("./Content/Visita/VisitanteTipo/VisitanteTipo")
);
const VisitaVisitanteEmpresa = lazy(
  () => import("./Content/Visita/VisitanteEmpresa/VisitanteEmpresa")
);
const VisitaReporte = lazy(() => import("./Content/Visita/Reportes/Reporte"));

export default function Rutas() {
  const { realm } = useContext(authContext);

  return (
    <Routes>
      <Route
        path="ActivoFijoActivo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoActivo />
          </Suspense>
        }
      />
      <Route
        path="ActivoFijoCategoria"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoCategoria />
          </Suspense>
        }
      />
      <Route
        path="ActivoFijoDepartamento"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoDepartamento />
          </Suspense>
        }
      />

      <Route
        path="ActivoFijoDepreciacion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoDepreciacion />
          </Suspense>
        }
      />

      <Route
        path="ActivoFijoGrupo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoGrupo />
          </Suspense>
        }
      />

      <Route
        path="ActivoFijoResponsable"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ActivoFijoResponsable />
          </Suspense>
        }
      />

      <Route
        path="BancoCargo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoCargo />
          </Suspense>
        }
      />

      <Route
        path="BancoCheque"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoCheque />
          </Suspense>
        }
      />

      <Route
        path="BancoChequeConfiguracionImpresion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoChequeConfiguracionImpresion />
          </Suspense>
        }
      />

      <Route
        path="BancoChequeOtros"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoChequeOtros />
          </Suspense>
        }
      />

      <Route
        path="BancoChequeAplicador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoChequeAplicador />
          </Suspense>
        }
      />

      <Route
        path="BancoConciliacion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoConciliacion />
          </Suspense>
        }
      />
      <Route
        path="BancoCuenta"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoCuenta />
          </Suspense>
        }
      />
      <Route
        path="BancoCredito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoCredito />
          </Suspense>
        }
      />
      <Route
        path="BancoSolicitud"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoSolicitud />
          </Suspense>
        }
      />
      <Route
        path="BancoSolicitudAutorizar"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoSolicitudAutorizar />
          </Suspense>
        }
      />
      <Route
        path="BancoSolicitudOtros"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoSolicitudOtros />
          </Suspense>
        }
      />
      <Route
        path="BancoTransferencia"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoTransferencia />
          </Suspense>
        }
      />
      <Route
        path="BancoTransferenciaCuenta"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoTransferenciaCuenta />
          </Suspense>
        }
      />
      <Route
        path="BancoTransferenciaAplicador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoTransferenciaAplicador />
          </Suspense>
        }
      />
      <Route
        path="BancoTransferenciaOtros"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoTransferenciaOtros />
          </Suspense>
        }
      />
      <Route
        path="BancoDeposito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoDeposito />
          </Suspense>
        }
      />
      <Route
        path="BancoReporte"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <BancoReporte />
          </Suspense>
        }
      />
      <Route
        path="Compania"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Compania />
          </Suspense>
        }
      />
      <Route
        path="Companias"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            {realm === "1" ? <Companias /> : <Compania />}
          </Suspense>
        }
      />
      <Route
        path="Importaciones"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Importaciones />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadCatalogo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadCatalogo />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadCentroCosto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadCentroCosto />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadConfiguraciones"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadConfiguraciones />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadEjercicioContable"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadEjercicioContable />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadEntradaDiario"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadEntradaDiario />
          </Suspense>
        }
      />
      <Route
        path="ContabilidadPasarMovimiento"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadPasarMovimientoCuenta />
          </Suspense>
        }
      />

      <Route
        path="ContabilidadPeriodoContable"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadPeriodoContable />
          </Suspense>
        }
      />

      <Route
        path="ContabilidadPresupuesto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadPresupuesto />
          </Suspense>
        }
      />

      <Route
        path="ContabilidadReportes"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadReportes />
          </Suspense>
        }
      />

      <Route
        path="ContabilidadReportesDGII"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <ContabilidadReportesDGII />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarClienteGrupo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarClienteGrupo />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarCliente"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarCliente />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarLocalidad"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarClienteLocalidad />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarCotizacion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarCotizacion />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarCotizacionEstado"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarCotizacionEstado />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarCuadreCaja"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarCuadreCaja />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarDespacho"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarDespacho />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarConfiguracion />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarFactura"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarFactura />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarNotaCredito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarNotaCredito />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarNotaCreditoAplicador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarNotaCreditoAplicador />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarNotaDebito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarNotaDebito />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarPrefactura"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarPrefactura />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarProforma"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarProforma />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarRecibo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarRecibo />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarReciboAplicador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarReciboAplicador />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarReciboManual"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarReciboManual />
          </Suspense>
        }
      />

      <Route
        path="CuentasCobrarRestaurantOrdenMesa"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarRestaurantOrdenMesa />
          </Suspense>
        }
      />
      <Route
        path="CuentasCobrarRestaurantMesa"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarRestaurantMesa />
          </Suspense>
        }
      />
      <Route
        path="CuentasCobrarVendedor"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarVendedor />
          </Suspense>
        }
      />
      <Route
        path="CuentasCobrarReportes"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarReportes />
          </Suspense>
        }
      />
      <Route
        path="CuentasCobrarResumenFacturaConsumo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarResumenFacturaConsumo />
          </Suspense>
        }
      />
      <Route
        path="CuentasCobrarSalidaCaja"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasCobrarSalidasCaja />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarBuscador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarBuscador />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarConfiguracion />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarFacturaCompra"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarFacturaCompra />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarFacturaServicio"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarFacturaServicio />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarNotaCredito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarNotaCredito />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarNotaCreditoAplicador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarNotaCreditoAplicador />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarNotaDebito"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarNotaDebito />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarOrdenCompra"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarOrdenCompra />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarPesada"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarPesada />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarPesador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarPesador />
          </Suspense>
        }
      />

      <Route
        path="cuentas-pagar-comprobantes-especial"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarComprobanteEspecial />
          </Suspense>
        }
      />

      <Route
        path="CuentasPagarReembolso"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarReembolso />
          </Suspense>
        }
      />

      <Route
        path="CuentasPagarReportes"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarReportes />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarSuplidor"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarSuplidor />
          </Suspense>
        }
      />
      <Route
        path="CuentasPagarSuplidorGrupo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <CuentasPagarSuplidorGrupo />
          </Suspense>
        }
      />

      <Route
        path="/"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <DashBoard />
          </Suspense>
        }
      />

      <Route
        path="DashBoard"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <DashBoard />
          </Suspense>
        }
      />

      <Route
        path="Eventos"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Eventos />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoPrestamoAutorizar"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoPrestamoAutorizar />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoConfiguracion />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoChequePrestamo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoChequePrestamo />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoPrestamo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoPrestamo />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoRecibo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoRecibo />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoReporte"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoReporte />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoSolicitudPagoPrestamo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoSolicitudPagoPrestamo />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoSolicitudPagoPrestamoAutorizar"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoSolicitudPagoPrestamoAutorizar />
          </Suspense>
        }
      />

      <Route
        path="FinanciamientoTransferenciaPrestamo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <FinanciamientoTransferenciaPrestamo />
          </Suspense>
        }
      />

      <Route
        path="InventarioAlmacen"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioAlmacen />
          </Suspense>
        }
      />

      <Route
        path="InventarioAlmacenGrupo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioAlmacenGrupo />
          </Suspense>
        }
      />

      <Route
        path="InventarioConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioConfiguracion />
          </Suspense>
        }
      />

      <Route
        path="InventarioConduceAlmacen"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioConduceAlmacen />
          </Suspense>
        }
      />

      <Route
        path="InventarioCorteInventario"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioCorteInventario />
          </Suspense>
        }
      />

      <Route
        path="InventarioCorteInventarioConteo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioCorteInventarioConteo />
          </Suspense>
        }
      />

      <Route
        path="InventarioEntradaAlmacen"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioEntradaAlmacen />
          </Suspense>
        }
      />

      <Route
        path="InventarioLiquidacionAduanalTipoGasto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioLiquidacionAduanalTipoGasto />
          </Suspense>
        }
      />

      <Route
        path="InventarioLiquidacionAduanal"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioLiquidacionAduanal />
          </Suspense>
        }
      />

      <Route
        path="InventarioProduccion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProduccion />
          </Suspense>
        }
      />

      <Route
        path="InventarioProduccionEmpacador"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProduccionEmpacador />
          </Suspense>
        }
      />

      <Route
        path="InventarioProduccionEmpaque"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProduccionEmpaque />
          </Suspense>
        }
      />

      <Route
        path="InventarioProduccionEmpaqueConfirmar"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProduccionEmpaqueConfirmar />
          </Suspense>
        }
      />

      <Route
        path="InventarioProduccionFormula"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProduccionFormula />
          </Suspense>
        }
      />

      <Route
        path="InventarioProducto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProducto />
          </Suspense>
        }
      />

      <Route
        path="InventarioProyecto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioProyecto />
          </Suspense>
        }
      />

      <Route
        path="InventarioTipoInventario"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioTipoInventario />
          </Suspense>
        }
      />
      <Route
        path="InventarioGrupoProducto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioGrupoProducto />
          </Suspense>
        }
      />
      <Route
        path="InventarioReportes"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioReportes />
          </Suspense>
        }
      />
      <Route
        path="InventarioSubGrupoProducto"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioSubGrupoProducto />
          </Suspense>
        }
      />
      <Route
        path="InventarioSalidaAlmacen"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioSalidaAlmacen />
          </Suspense>
        }
      />
      <Route
        path="InventarioRequisicion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioRequisicion />
          </Suspense>
        }
      />
      <Route
        path="InventarioRequisicionAutorizar"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioRequisicionAutorizar />
          </Suspense>
        }
      />
      <Route
        path="InventarioTransferenciaAlmacen"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <InventarioTransferenciaAlmacen />
          </Suspense>
        }
      />

      <Route
        path="Nomina"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Nomina />
          </Suspense>
        }
      />

      <Route
        path="NominaConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaConfiguracion />
          </Suspense>
        }
      />
      <Route
        path="NominaDepartamento"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaDepartamento />
          </Suspense>
        }
      />
      <Route
        path="NominaEmpleado"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaEmpleado />
          </Suspense>
        }
      />
      <Route
        path="NominaPrestamo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaPrestamo />
          </Suspense>
        }
      />
      <Route
        path="NominaNovedad"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaNovedad />
          </Suspense>
        }
      />
      <Route
        path="NominaNovedadFijo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaNovedadFijo />
          </Suspense>
        }
      />

      <Route
        path="NominaNovedadVariable"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <NominaNovedadVariable />
          </Suspense>
        }
      />

      <Route
        path="OrdenServicioTecnico"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <OrdenServicioTecnico />
          </Suspense>
        }
      />

      <Route
        path="OrdenServicioEstado"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <OrdenServicioEstado />
          </Suspense>
        }
      />

      <Route
        path="OrdenServicioTipo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <OrdenServicioTipo />
          </Suspense>
        }
      />

      <Route
        path="OrdenServicioOrden"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <OrdenServicioOrden />
          </Suspense>
        }
      />

      <Route
        path="Perfil"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Perfil />
          </Suspense>
        }
      />

      <Route
        path="RRHHAccion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <RRHHAccion />
          </Suspense>
        }
      />

      <Route
        path="RRHHNominaRegalia"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <RRHHNominaRegalia />
          </Suspense>
        }
      />

      <Route
        path="RRHHPosicion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <RRHHPosicion />
          </Suspense>
        }
      />

      <Route
        path="RRHHReporte"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <RRHHReporte />
          </Suspense>
        }
      />

      <Route
        path="RRHHSolicitudEmpleo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <RRHHSolicitudEmpleo />
          </Suspense>
        }
      />

      <Route
        path="SueldoPorNomina"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <SueldoPorNomina />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaArea"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaArea />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaAreaEstadosSevicio"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaAreaEstadosServicio />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaConfiguracion />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaMecanico"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaMecanico />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaOrden"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaOrden />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaOrdenTipo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaOrdenTipo />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaVehiculo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaVehiculo />
          </Suspense>
        }
      />

      <Route
        path="TallerMecanicaReportes"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <TallerMecanicaReporte />
          </Suspense>
        }
      />

      <Route
        path="Usuarios"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <Usuarios />
          </Suspense>
        }
      />

      <Route
        path="VisitaConfiguracion"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaConfiguracion />
          </Suspense>
        }
      />

      <Route
        path="VisitaArea"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaArea />
          </Suspense>
        }
      />

      <Route
        path="VisitaDestino"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaDestino />
          </Suspense>
        }
      />

      <Route
        path="VisitaVisita"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaVisita />
          </Suspense>
        }
      />

      <Route
        path="VisitaVisitante"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaVisitante />
          </Suspense>
        }
      />

      <Route
        path="VisitaVisitanteTipo"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaVisitanteTipo />
          </Suspense>
        }
      />

      <Route
        path="VisitaVisitanteEmpresa"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaVisitanteEmpresa />
          </Suspense>
        }
      />

      <Route
        path="VisitaReporte"
        element={
          <Suspense fallback={<div>Cargando...</div>}>
            <VisitaReporte />
          </Suspense>
        }
      />
    </Routes>
  );
}
