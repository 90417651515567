import React, { ReactNode, useEffect, useState } from "react";
import classes from "./dialog.module.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TextField } from "../Inputs";

/**
 * Muestra cuadro de dialogo para solicitar accion del usuario
 * @param Open booleano que indica si se muestra o no
 * @param Titulo texto del titulo
 * @param EstiloTitulo color o estilo, Default, Warning, Danger, Success, Information
 * @param Mensaje texto del cuerpo
 * @param TextoBtn1 texto del primer botón
 * @param TextoBtn2 texto del segundo botón, si esta vacio no muestra el botón
 * @param AccionDialogBtn1 acción del primer botón
 * @param AccionDialogBtn2 acción del segundo botón
 */
export default function Dialog({
  open = false,
  mensaje,
  estiloTitulo,
  titulo,
  accionDialogBtn1,
  accionDialogBtn1WithValue,
  textoBtn1,
  accionDialogBtn2,
  textoBtn2,
  textFieldLabel,
  children,
  disableDialogBtn1,
  disableDialogBtn2,
}: {
  open?: boolean;
  mensaje: string;
  estiloTitulo: "Default" | "Warning" | "Danger" | "Success" | "Information";
  titulo: string;
  accionDialogBtn1?: () => void;
  accionDialogBtn1WithValue?: (value: string) => void;
  textoBtn1: string;
  accionDialogBtn2: () => void;
  textoBtn2: string;
  textFieldLabel?: string;
  children?: ReactNode;
  disableDialogBtn1?: boolean;
  disableDialogBtn2?: boolean;
}) {
  const [value, setValue] = useState("");
  const [grabando, setGrabando] = useState(false);

  useEffect(() => {
    setGrabando(false);
  }, [open]);

  return (
    <Modal isOpen={open} zIndex={2000}>
      <ModalHeader className={classes[estiloTitulo]}>{titulo}</ModalHeader>
      <ModalBody>
        {mensaje}
        {children}
      </ModalBody>

      {accionDialogBtn1WithValue && (
        <TextField
          name="value"
          label={textFieldLabel}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      )}

      <ModalFooter>
        <Button
          onClick={() => {
            setGrabando(true);

            if (accionDialogBtn1WithValue) {
              accionDialogBtn1WithValue(value);
              return;
            }

            if (accionDialogBtn1) {
              accionDialogBtn1();
              return;
            }
          }}
          color="success"
          disabled={grabando || disableDialogBtn1}
        >
          {textoBtn1}
        </Button>{" "}
        {textoBtn2 !== "" && (
          <Button
            onClick={accionDialogBtn2}
            color="danger"
            disabled={grabando || disableDialogBtn2}
          >
            {textoBtn2}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
